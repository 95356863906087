import React from "react";
import PrimaryButton from "../button";

const PricingTier = ({ title, text, price, buttonText, buttonLink, showInfo = true }) => (
  <div className="bg-white pv4 ph3 tc ">
    <h3 className="f3 b mb4">{title}</h3>
    <p className="f5 mb3">{text}</p>
    <p className="f5 b mb5">{price}</p>
    <PrimaryButton link={buttonLink} text={buttonText} extraClasses={"f6 inline-flex"}/>
    {showInfo ? <p className="f7 tracked b ttu tc">No credit card required</p> : ""}
  </div>
);

export default PricingTier;
