import React from "react";
import Layout from "../components/layout";
import Container from "../components/container";
import PricingTier from "../components/pricingtier";

const PricingPage = () => (
  <Layout pageTitle="Pricing">
    <main>
      <Container extraClasses={"office"} maxWidth={1200}>
        <h3 className="f3 white mb4 tc">Get fully-featured testing for teams</h3>
        <h4 className="f5 white tc sb mb4">Teams save time and improve quality and
          products benefit from our next generation of user interface testing</h4>

        <div className="pricing-tiers">
          <PricingTier
            title="Starter"
            text="5 users / 10GB"
            price="£39 per month"
            buttonText="Start free 30 day trial"
            buttonLink="https://app.videofirst.io/register/"
          />
          <PricingTier
            title="Pro"
            text="10 users / 50GB"
            price="£99 per month"
            buttonText="Start free 30 day trial"
            buttonLink="https://app.videofirst.io/register/"
          />
          <PricingTier
            title="Enterprise"
            text="Custom users / data"
            price="Talk to us"
            buttonText="Get in Touch"
            buttonLink="mailto:info@videofirst.io?subject=Video First Enterprise Pricing Inquiry"
            showInfo={false}
          />
        </div>
      </Container>
    </main>
  </Layout>
);

export default PricingPage;
